import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import auth from './slices/auth';
import pages from './slices/pages';
import stories from './slices/stories';
import navigation from './slices/navigation';
import assignments from './slices/assignments';
import mediaControl from './slices/mediaControl';
import redirectActiveTab from './slices/activeTab';

export default configureStore({
  middleware: getDefaultMiddleware =>
    process.env.REACT_APP_ENV === 'dev' ? [...getDefaultMiddleware(), logger] : [...getDefaultMiddleware()],
  reducer: {
    auth,
    pages,
    stories,
    navigation,
    assignments,
    mediaControl,
    redirectActiveTab
  }
});
