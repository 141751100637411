import { FRAME_ATTACHMENT_TYPES } from '../components/FrameAttachment/FrameAttachment';
import React from 'react';
import axios from 'axios';

import AudioIcon from '../icons/Audio';
import VideoIcon from '../icons/Video';
import FileIcon from '../icons/File';
import moment from 'moment';
import { STYLE_VARS } from '../constants';

export const checkFrameInWindow = frame => {
  if (frame && frame.newWindow && frame.attachmentType === FRAME_ATTACHMENT_TYPES.URL) {
    openWindow(frame);
  }
};

const openWindow = ({ title, attachment, windowWidth = 800, windowHeight = 600 }) => {
  const { width: sw, height: sh } = window.screen;
  window.open(
    attachment,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${windowWidth},height=${windowHeight},top=${
      sh / 2 - windowHeight / 2
    },left=${sw / 2 - windowWidth / 2}`
  );
};

export const tupleBy3 = items => {
  let tuples = [];

  for (let i = 0; i < items.length; i += 3) {
    tuples.push(items.slice(i, i + 3));
  }

  return tuples;
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

export const getAttachmentIcon = mimeType => {
  const [mainType] = mimeType.split('/');

  switch (mainType) {
    case 'audio':
      return <AudioIcon />;
    case 'video':
      return <VideoIcon />;
    default:
      return <FileIcon />;
  }
};

export const omit = (obj, omittedKey) =>
  Object.keys(obj).reduce((acc, key) => (key === omittedKey ? acc : { ...acc, [key]: obj[key] }), {});

export const omitEmptyKeys = obj => {
  return Object.keys(obj)
    .filter(key => !!obj[key])
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
};

export const getShortLink = async (longUrl, body) => {
  const res = await axios.post('/storyLink', { longUrl, ...body });

  return res.data.shortUrl;
};

export const setStyleProperties = story => {
  const docStyle = document.documentElement.style;
  setGlobalStyle(docStyle, STYLE_VARS.PRIMARY_COLOR, story.primaryColor);
  setGlobalStyle(docStyle, STYLE_VARS.SECONDARY_COLOR, story.secondaryColor);
  setGlobalStyle(docStyle, STYLE_VARS.BACKGROUND_COLOR, story.backgroundColor);
  setGlobalStyle(docStyle, STYLE_VARS.ACTIVE_FRAME_BORDER_COLOR, story.activeFrameBorderColor);
  setGlobalStyle(docStyle, STYLE_VARS.FONT_FAMILY, story.fontFamily);
  setGlobalStyle(docStyle, STYLE_VARS.LANGUAGE_ICON, story.languageButtonIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.CLOSE_ICON, story.closeButtonIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.RESTART_ICON, story.restartStoryIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.BACKPACK_ICON, story.backpackIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.JOURNAL_ICON, story.journalIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.NEXT_ICON, story.nextButtonIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.BACK_ICON, story.backButtonIcon);
  setGlobalIconStyle(docStyle, STYLE_VARS.LANGUAGE_ICON, story.languageButtonIcon);
};

export const setGlobalStyle = (docStyle, property, value) => {
  if (value) docStyle.setProperty(property, value);
};

const setGlobalIconStyle = (docStyle, property, value) => {
  if (value) {
    docStyle.setProperty(property, `url(${value.url})`);
    docStyle.setProperty(`${property}-color`, value.color);
  }
};

export const removeStyleProperties = () => {
  const docStyle = document.documentElement.style;
  Object.values(STYLE_VARS).forEach(property => docStyle.removeProperty(property));
};

export const newId = () => {
  let id = 0;
  return id++;
};

export const msToTime = time => {
  var seconds = Math.floor((time / 1000) % 60),
    minutes = Math.floor((time / (1000 * 60)) % 60),
    hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  return formatStringTime(hours, minutes, seconds);
};

export const secToTime = time => {
  var hours = Math.floor(time / 3600),
    minutes = Math.floor((time - hours * 3600) / 60),
    seconds = time - hours * 3600 - minutes * 60;
  return formatStringTime(hours, minutes, seconds);
};

const formatStringTime = (hours, minutes, seconds) => {
  hours = hours ? hours + (hours > 1 ? ' hours ' : ' hour ') : '';
  minutes = minutes ? minutes + (minutes > 1 ? ' minutes ' : ' minute ') : '';
  seconds = seconds ? seconds + (seconds > 1 ? ' seconds ' : ' second ') : '';

  return hours + minutes + seconds;
};

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const showEndDate = endDate => {
  const today = new Date().getTime();
  const dueDay = moment(endDate).valueOf();
  const diff = (dueDay - today) / (1000 * 3600 * 24);
  if (diff <= 30) return true;
  return false;
};

export const getLanguageField = (field, lang) => {
  return field?.[lang] || field?.default || field;
};
